<template>
    <div class="register-page">
        <div class="register-container">
            <img src="@/components/assets/registration-img.png" alt="">
            <h1 class="register-header">{{ $t('generalTabs.registration') }}</h1>
            <div class="register-first-inputs-block">
                <h2 class="register-block-header">{{ $t('generalTabs.personalInfo') }}</h2>
                <div class="register-double-inputs">
                    <div class="register-input-block double-inputs">
                        <label class="register-lable">{{ $t('generalTabs.firstName') }}</label>
                        <input class="register-input" v-model="processedFirstName" type="text">
                    </div>

                    <div class="register-input-block double-inputs">
                        <label class="register-lable">{{ $t('generalTabs.lastName') }}</label>
                        <input class="register-input" v-model="processedLastName" type="text">
                    </div>
                </div>
                <div class="register-input-block">
                    <label class="register-lable">{{ $t('global.byUsername') }}</label>
                    <input class="register-input" v-model="processedUsername" type="text">
                    <span v-if="!isUsernameValid" class="register-error">{{ $t('global.fieldMustBe') }}</span>
                </div>
            </div>
            <hr />
            <div>
                <h2 class="register-block-header">{{ $t('generalTabs.contactInfo') }}</h2>
                <div class="register-double-inputs">
                    <div class="register-input-block double-inputs">
                        <label class="register-lable">{{ $t('global.email') }}</label>
                        <input class="register-input" v-model="processedEmail" type="email">
                        <span v-if="!processedIsFormValid" class="register-error">{{ $t('global.invalidEmail') }}</span>
                        <div class="v-text-field__details" v-if="emailSuggestion" @click="updateEmail(emailSuggestion)">
                            <div class="v-messages theme--light success--text" role="alert">
                                <div class="v-messages__wrapper">
                                    <div class="v-messages__message">{{ $t('global.didYouMean') }} {{
                                        emailSuggestion }}?</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="register-input-block double-inputs">
                        <label class="register-lable">{{ $t('generalTabs.selectCountry') }}</label>
                        <input class="register-input" list="brow" placeholder="United States"
                            v-model="processedCountry">
                        <datalist id="brow">
                            <option v-for="(country, index) in countries" :key="index" :value="country.text" />
                        </datalist>
                    </div>
                </div>
                <div class="register-input-block">
                    <label class="register-lable">{{ $t('generalTabs.phoneNumber') }}</label>
                    <vue-tel-input mode="international" @input="phoneFormatValidate" v-model="processedPhone"
                        :default-country="clientIpCountry.name" styleClasses='phone-validator'
                        :input-options="{ placeholder: $t('global.phoneNumber'), styleClasses: 'phone-validator' }">
                        <template slot="arrow-icon"> <span class="vti__dropdown-arrow">&nbsp;▼</span></template>
                    </vue-tel-input>
                    <div class="v-text-field__details" v-if="!isPhoneFormatValid && _.size(phone) > 0">
                        <div class="v-messages theme--light error--text" role="alert">
                            <div class="v-messages__wrapper">
                                <div class="v-messages__message">{{ $t('global.invalidPhone') }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="welcome-agree">
                <label class="checkbox-container">
                    <input v-model="isNotChecked" class="welcome-checkbox" type="checkbox">
                    <div :class="isNotChecked ? 'custom-checkbox active' : 'custom-checkbox'"></div>
                    <div class="checkmark" :class="isNotChecked ? 'active' : ''"></div>
                </label>
                <p class="welcome-agree-text">{{ $t("generalTabs.iAgree") }}
                    <a href="/PrivacyPolicy" target="_blank" class="privacy-policy">{{ $t("generalTabs.privacyPolicy")
                        }}</a>
                </p>
            </div>
            <CloudFlareCaptcha ref="CloudFlareCaptcha" />
            <div class="register-buttons">
                <button class="register-btn cancel-btn" @click="cancel">{{ $t('global.cancel') }}</button>
                <button class="register-btn" :class="isActiveButton ? 'register-inactive-btn' : ''" @click="submit">{{
                    $t('global.continue') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ApiService from '@/services/ApiService';
import VueService from '@/services/VueService';

export default {
    props: [
        'isTrial',
        'goToRegistrationPage',
        'isFormValid',
        'updateIsFormValid',
        'leadId',
        'firstName',
        'updateFirstName',
        'lastName',
        'updateLastName',
        'email',
        'updateEmail',
        'emailSuggestion',
        'username',
        'updateUsername',
        'isUsernameValid',
        'phone',
        'updatePhone',
        'phoneFormatValidate',
        'clientIpCountry',
        'isPhoneFormatValid',
        'country',
        'updateCountry',
        'countries',
        'reff',
        'updateReff',
        'promoCode',
        'updatePromoCode',
        'cancel'
    ],
    components: {
        CloudFlareCaptcha: () => import("../../../../components/CloudFlareCaptcha.vue")
    },
    data() {
        return {
            isNotChecked: false,
            rules: {
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value);
                }
            },
            clientIpPromise: ApiService.publicApi.getCurrentClientIp(),
            invitationToken: null
        }
    },
    computed: {
        ...mapGetters(['siteInfo']),
        processedFirstName: {
            get() {
                return this.firstName;
            },
            set(value) {
                this.updateFirstName(value);
            }
        },
        processedLastName: {
            get() {
                return this.lastName;
            },
            set(value) {
                this.updateLastName(value);
            }
        },
        processedEmail: {
            get() {
                return this.email;
            },
            set(value) {
                this.updateEmail(value);
            }
        },
        processedUsername: {
            get() {
                return this.username;
            },
            set(value) {
                this.updateUsername(value);
            }
        },
        processedPhone: {
            get() {
                return this.phone;
            },
            set(value) {
                this.updatePhone(value);
            }
        },
        processedCountry: {
            get() {
                return this.country;
            },
            set(value) {
                const obj = this.countries.find((item) => item.text === value)
                if(obj) this.updateCountry(obj.value);
            }
        },
        processedReff: {
            get() {
                return this.reff;
            },
            set(value) {
                this.updateReff(value);
            }
        },
        processedPromoCode: {
            get() {
                return this.promoCode;
            },
            set(value) {
                this.updatePromoCode(value);
            }
        },
        processedIsFormValid: {
            get() {
                return this.isFormValid;
            },
            set(value) {
                this.updateIsFormValid(value);
            }
        },
        isActiveButton() {
            if (!this.isUsernameValid || !this.country || !this.isPhoneFormatValid || !this.isNotChecked || !this.processedIsFormValid || !this.captchaToken()) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
        processedEmail(value) {
            this.processedIsFormValid = this.rules.email(value)
        }
    },
    async created() {
          const loader = this.$loading.show();
          try {
            if (this.$route.query.invitationToken) {
              this.invitationToken = this.$route.query.invitationToken;
              const res = await ApiService.publicApi.isAllowToRegisterByLinkId(this.invitationToken);
              if (res.error) {
                return this.$router.push(`/Login`);
              }
              this.processedPromoCode = res.clientId;
            } else {
              return this.$router.push(`/Login`);
            }
          } finally {
            loader.hide();
          }
    },
    methods: {
        ...mapActions(["setToken"]),
        async submit() {
            const clientData = {
                ip: await this.clientIpPromise,
                email: this.processedEmail,
                username: this.processedUsername,
                firstName: this.processedFirstName,
                lastName: this.processedLastName,
                phone: this.processedPhone,
                isTrial: false,
                country: this.processedCountry,
                googleCaptcha: this.captchaToken(),
                language: this.$i18n.locale,
                promoCode: this.processedPromoCode,
                invitationToken: this.invitationToken
            };
            this.resetCaptcha();
            const res = await ApiService.clientsApi.register(clientData);

            if (res.alreadyExist) {
                if (res.byPhone || res.byEmail) {
                    return VueService.showToastMessage({
                        message: `${res.byEmail ? this.$t('global.yourEmail') : this.$t('global.phoneNumber')} ${this.$t('global.alreadyExist')}`,
                        type: 'error'
                    });
                } else if (res.error101) {
                    return VueService.showToastMessage({
                        message: this.$t('global.error101'),
                        type: 'error'
                    });
                } else if (res.byUsername) {
                    return VueService.showToastMessage({
                        message: `${this.$t('global.byUsername')} ${this.$t('global.alreadyExist')}`,
                        type: 'error'
                    });
                }
            }

            if (res.token) {
                await this.setToken(res.token);
                await this.$router.push("Subscriptions");
            }

        },
        captchaToken() {
            return this.$refs.CloudFlareCaptcha.getCapatchToken();
        },
        resetCaptcha() {
            return this.$refs.CloudFlareCaptcha.resetCaptcha();
        }
    }
};
</script>
<style>
h1,
h2,
p,
button,
input,
label {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
    padding: 0;
}

hr {
    color: white;
    opacity: 12%;
    margin: 20px 0;
}

.vti__dropdown:hover,
.vti__dropdown.open {
    background-color: transparent;
}

.vti__dropdown-list {
    background-color: #1D1B29;
    border: 1px solid #3C3A46;
    border-radius: 8px;
}

.register-page {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 30px;
    align-items: center;
}

.register-container {
    padding: 0 32px;
    background-color: #1F212799;
    backdrop-filter: blur(10px);
    border-radius: 12px;
    min-width: 600px;
}

.register-header {
    font-size: 28px;
    font-weight: 700;
    line-height: 35.28px;
    margin-bottom: 24px;
}

.register-block-header {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.16px;
}

.register-double-inputs {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
}

.register-lable {
    font-size: 13px;
    font-weight: 400;
    line-height: 16.38px;
    margin-bottom: 8px;
}

.register-input-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 12px;
}

.register-input {
    font-size: 15px;
    font-weight: 400;
    line-height: 18.9px;
    background-color: #FFFFFF0A;
    border-radius: 8px;
    border: 1px solid #FFFFFF1A;
    padding: 12px 16px;
    outline: none;
}

.register-input:focus {
    border: 1px solid #4E4BCD;
}

.double-inputs {
    width: 48%;
}

.welcome-agree {
    display: flex;
    align-items: center;
    margin: 24px 0;
}

.welcome-agree p {
    color: #FFFFFF99;
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 19, 5px;
    margin-left: 9px;
}

.register-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.register-btn {
    width: fit-content;
    border: none;
    outline: none;
    background-color: #4E4BCD;
    font-weight: 600;
    font-size: 14px;
    line-height: 17.64px;
    border-radius: 8px;
    padding: 12px 21.5px;
    margin-top: 8px;
    transition: 0.2s all;
}

.cancel-btn {
    background-color: transparent;
    color: #4E4BCD;
    border: 1px solid #4E4BCD;
}


.privacy-policy {
    color: #918FFA;
    text-decoration: underline;
    cursor: pointer;
}

.welcome-checkbox {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.checkbox-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.custom-checkbox {
    width: 18px;
    height: 18px;
    border: 2px solid #888;
    border-radius: 2px;
    background-color: transparent;
    cursor: pointer;
}

.custom-checkbox.active {
    background-color: #4E4BCD;
    border: 2px solid #4E4BCD;
}

.checkmark {
    position: absolute;
    top: 10%;
    left: 35%;
    transform: rotate(45deg);
    width: 6px;
    height: 10px;
    border-right: 2px solid #1F212799;
    border-bottom: 2px solid #1F212799;
    opacity: 0;
}

.checkmark.active {
    opacity: 1;
}

.register-error {
    font-family: 'Wix Madefor Display', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 19.5px;
    color: #FF254C;
}

.register-inactive-btn {
    background-color: #3C3A46;
    color: #1D1B29;
    pointer-events: none;
}

.vue-tel-input {
    font-size: 15px;
    font-weight: 400;
    line-height: 18.9px;
    background-color: #FFFFFF0A;
    border-radius: 8px;
    border: 1px solid #FFFFFF1A;
    outline: none;
}

@media only screen and (max-width: 600px) {
    img {
        width: 100%;
    }

    .register-container {
        min-width: 10%;
    }
}
</style>
